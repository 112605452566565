import {
  Accommodation,
  AccommodationRequest,
  AccommodationSpecificity,
  Patient,
  PatientOutcome,
  PerformerRequest,
  ThesaurusItem,
} from '@ambuliz/sabri-core';
import { Card, Stack, TextField, Typography } from '@mui/material';
import { DateTimeInput, TimeFlowLine } from 'common/components';
import CommentIcon from 'common/components/Icons/Comment';
import EditIcon from 'common/components/Icons/Edit';
import EntryDashedIcon from 'common/components/Icons/EntryDashed';
import HeartBeatIcon from 'common/components/Icons/HeartBeat';
import ListIcon from 'common/components/Icons/List';
import PathIcon from 'common/components/Icons/Path';
import TicketIcon from 'common/components/Icons/Ticket';
import UnitIcon from 'common/components/Icons/Unit';
import { ThesaurusItemCard } from 'common/components/ThesaurusReasonAutocomplete/ThesaurusItemCard';
import { ThesaurusReasonAutocomplete } from 'common/components/ThesaurusReasonAutocomplete/ThesaurusReasonAutocomplete';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { format, formatDistanceToNow, isPast } from 'date-fns';
import { fr } from 'date-fns/locale';
import PerformersList from 'kurt/components/PatientFlowKanban/VisitDetails/PerformersList';
import { EditablePatientOutcomeRow, PatientOutcomeRow } from 'kurt/components/PatientOutcome';
import SpecificityAutocomplete from 'kurt/components/SpecificityAutocomplete';
import { SpecificityChip } from 'kurt/components/SpecifityChip';
import MutationStatusChip from 'kurt/components/StepStatus/MutationStatusChip';
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import DetailsRow from '../../DetailsRow';
import HorizontalDivider from '../../HorizontalDivider';
import { MutationStep } from '../../context/Step';
import { cloneAccommodation } from '../Accommodation/AccommodationDetailsContent';

type MutationDetailsContentProps = {
  step: MutationStep;
  editable?: boolean;
  onChange?: (accommodationRequest: AccommodationRequest) => void;
  onDestinationChange?: (accommodation: Accommodation) => void;
};

const MutationDetailsContent = ({ step, editable, onChange, onDestinationChange }: MutationDetailsContentProps) => {
  const currentRequest = step.step.performerRequests?.find((request) =>
    ['REQUESTED', 'ACCEPTED'].includes(request.status)
  );
  const isToAddress = step.step.status === 'REQUESTED' && !step.step.performerRequests?.length;
  const isRequested = step.step.status === 'REQUESTED' && step.step.performerRequests?.length;
  const isEnded = step.destination?.status === 'IN_PROGRESS' && step.step.status === 'ACCEPTED';
  const isMutationPast = step.step.status === 'ACCEPTED' && step.origin?.endAt && isPast(step.origin.startAt);
  const since =
    step.step.status === 'ACCEPTED' && step.origin?.endAt
      ? formatDistanceToNow(step.origin.endAt, { locale: fr, includeSeconds: true })
      : '-';

  const destinationUnit = step.destination?.unit || currentRequest?.performer;

  const handleEndAtChange = (endAt: Date | null) => {
    if (!endAt || !step.destination) {
      return;
    }
    const clone = cloneAccommodation(step.destination);

    clone.startAt = endAt;
    onDestinationChange?.(clone);
  };

  const handleCommentChange = (comment: string) => {
    const clone = cloneMutation(step.step);

    clone.comment = comment;
    onChange?.(clone);
  };

  const handleSpecificitiesChange = (specificities: AccommodationSpecificity[]) => {
    const clone = cloneMutation(step.step);

    clone.specificities = specificities;
    onChange?.(clone);
  };

  const handleReasonChange = (value: ThesaurusItem | string | null) => {
    const clone = cloneMutation(step.step);

    clone.thesaurusItem = (typeof value !== 'string' && value) || undefined;
    clone.reason = typeof value === 'string' ? value : value?.reason;
    onChange?.(clone);
  };

  const handlePatientOutcomeChange = (patientOutcome: PatientOutcome) => {
    const clone = cloneMutation(step.step);
    if (clone.visit) {
      clone.visit.patientOutcome = patientOutcome;
      onChange?.(clone);
    }
  };

  const { flow, type } = useFlowAndTypeFromUrl();
  const disableVtLink = useMemo(() => {
    return flow === 'admission' && type === 'mutation' && step.step.requestType === 'DIRECT_ADMISSION';
  }, [step.step.requestType, flow, type]);

  return (
    <Card sx={{ borderTopLeftRadius: 0, marginTop: '-1px' }} elevation={1}>
      <Stack divider={<HorizontalDivider />}>
        <Stack direction="row" padding={4}>
          <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" flex={1}>
            <Stack direction="row" gap={4} paddingX={2}>
              <Stack direction="row" gap={1}>
                <Stack direction="row" gap={2} alignItems="center">
                  <EditIcon color="secondary" sx={{ fontSize: 12 }} />
                  <Typography variant="body2" fontWeight={600} color="secondary">
                    {i18n.createdAt}
                  </Typography>
                </Stack>
                <Typography color="secondary" variant="body2">
                  {format(step.step.createdAt, 'dd/MM - HH:mm')}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <Typography variant="body2" fontWeight={600} color="secondary">
                  {isMutationPast ? i18n.since : i18n.in}
                </Typography>
                <Typography color="secondary" variant="body2">
                  {since}
                </Typography>
              </Stack>
            </Stack>
            <Stack padding="7px 8px">
              {isRequested ? (
                <MutationStatusChip status="PENDING" />
              ) : isToAddress ? (
                <MutationStatusChip status="TO_ADDRESS" />
              ) : step.step.status === 'REJECTED' ? (
                <MutationStatusChip status="BLOCKED" />
              ) : isEnded ? (
                <MutationStatusChip status="COMPLETED" />
              ) : !!step.destination?.bed ? (
                <MutationStatusChip status="VALIDATED" />
              ) : (
                <MutationStatusChip status="ACCEPTED" />
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" padding="16px 16px 16px 24px" gap={2} alignItems="center">
          <TimeFlowLine
            start={step.origin && ['IN_PROGRESS', 'COMPLETED'].includes(step.origin.status) ? 'filled' : 'dashed'}
            end={
              step.destination && ['IN_PROGRESS', 'COMPLETED'].includes(step.destination.status) ? 'filled' : 'dashed'
            }
            direction="vertical"
          />
          <Stack spacing={3} flex={1}>
            <Stack direction="row" gap={2} alignItems="center">
              <Stack direction="row" gap={2} flex={1}>
                {!!step.origin?.bed && (
                  <Typography fontWeight={600} padding="7px 8px">
                    {step.origin.bed.name}
                  </Typography>
                )}
                <Typography fontWeight={600} padding="7px 8px">
                  {step.origin?.unit?.name || step.step.directAdmissionOrigin}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" gap={2} alignItems="center">
              <Stack direction="row" gap={2} flex={1}>
                {!!step.destination?.bed && (
                  <Typography fontWeight={600} padding="7px 8px">
                    {step.destination.bed.name}
                  </Typography>
                )}
                <Typography fontWeight={600} padding="7px 8px">
                  {isToAddress ? i18n.mutationStatus.toAddress : destinationUnit?.name || '-'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" gap={2}>
            {step.destination && step.step.status === 'ACCEPTED' ? (
              editable ? (
                <DateTimeInput
                  onChange={handleEndAtChange}
                  value={step.destination.startAt}
                  minDate={step.origin?.startAt}
                  maxDate={step.destination?.endAt}
                  error={
                    step.origin?.startAt &&
                    (step.origin.startAt > step.destination.startAt ||
                      (step.destination.endAt && step.destination.startAt > step.destination.endAt))
                  }
                  size="small"
                  variant="filled"
                  noRange
                  clearButton={false}
                />
              ) : step.destination?.startAt ? (
                <>
                  <Typography>{format(step.destination.startAt, 'dd/MM/yyyy')}</Typography>
                  <Typography mr={8}>{format(step.destination.startAt, 'HH : mm')}</Typography>
                </>
              ) : (
                <Typography color="secondary.medium">{i18n.toDefine}</Typography>
              )
            ) : (
              <div>-</div>
            )}
          </Stack>
        </Stack>
        <Stack padding={4}>
          <Stack spacing={2} paddingLeft={2}>
            <DetailsRow
              Icon={TicketIcon}
              label={i18n.movementType}
              value={
                <Stack direction="row" alignItems="center" gap={2} padding={2}>
                  {step.step.requestType === 'DIRECT_ADMISSION' ? (
                    <EntryDashedIcon color="tertiary" sx={{ fontSize: 12 }} />
                  ) : (
                    <UnitIcon color="info" sx={{ fontSize: 12 }} />
                  )}
                  <Typography variant="body2">{i18n.mutationSummary.movementType[step.step.requestType]}</Typography>
                </Stack>
              }
            />
            <DetailsRow
              Icon={HeartBeatIcon}
              label={i18n.movementReason}
              value={
                editable ? (
                  <ThesaurusReasonAutocomplete
                    onChange={handleReasonChange}
                    value={step.step.thesaurusItem || step.step.reason}
                  />
                ) : (
                  step.step.reason && (
                    <ThesaurusItemCard variant="filled" thesaurus={step.step.thesaurusItem || step.step.reason} />
                  )
                )
              }
            />
            <DetailsRow
              Icon={UnitIcon}
              label={i18n.detailsDialog.common.responsibleUnit}
              value={step.origin?.responsibleUnit?.name}
            />
            <DetailsRow
              Icon={ListIcon}
              label={i18n.specificities}
              value={
                editable ? (
                  <SpecificityAutocomplete value={step.step.specificities || []} onChange={handleSpecificitiesChange} />
                ) : (
                  step.step.specificities?.length && (
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="center"
                      flexWrap="wrap"
                      alignSelf="center"
                      paddingLeft={2}
                    >
                      {step.step.specificities.map((specificity) => (
                        <SpecificityChip
                          key={specificity}
                          specificity={specificity}
                          sx={{ backgroundColor: palette.background.default }}
                        />
                      ))}
                    </Stack>
                  )
                )
              }
            />
            <DetailsRow
              Icon={CommentIcon}
              label={i18n.comment}
              value={
                editable ? (
                  <TextField
                    fullWidth
                    multiline
                    value={step.step.comment}
                    onChange={(event) => handleCommentChange(event.target.value)}
                    variant="filled"
                    size="small"
                  />
                ) : (
                  step.step.comment && (
                    <Typography variant="body2" whiteSpace="pre-line" padding={2}>
                      {step.step.comment}
                    </Typography>
                  )
                )
              }
            />
            <DetailsRow
              orientation={isToAddress ? 'horizontal' : 'vertical'}
              Icon={PathIcon}
              label={i18n.guidance}
              value={
                isToAddress ? undefined : <PerformersList performerRequests={step.step?.performerRequests || []} />
              }
            />
          </Stack>
        </Stack>
        {editable ? (
          <EditablePatientOutcomeRow
            patientOutcome={step.step?.visit?.patientOutcome}
            handlePatientOutcomeChange={handlePatientOutcomeChange}
          />
        ) : (
          <PatientOutcomeRow patient={step.step?.visit} disableVtLink={disableVtLink} />
        )}
      </Stack>
    </Card>
  );
};

const cloneMutation = (mutation: AccommodationRequest) => {
  const clone = AccommodationRequest.fromJSON({
    ...mutation.toJSON(),
    className: 'AccommodationRequest',
  }) as AccommodationRequest;

  if (mutation.visit) {
    clone.visit = Patient.fromJSON({
      ...mutation.visit.toJSON(),
      className: 'Patient',
    }) as Patient;
  }

  if (mutation.performerRequests) {
    clone.performerRequests = mutation.performerRequests.map(
      (performerRequest) =>
        PerformerRequest.fromJSON({
          ...performerRequest.toJSON(),
          className: 'PerformerRequest',
        }) as PerformerRequest
    );
  }
  return clone;
};

const useFlowAndTypeFromUrl = () => {
  const match = useMatch('*');

  const type = match?.params?.['*']?.split('/').slice(-2)[0];
  const flow = match?.params?.['*']?.split('/').slice(-3)[0];

  return {
    flow,
    type,
  };
};

export default MutationDetailsContent;
