import { ThesaurusItem } from '@ambuliz/sabri-core';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { useAuthentication } from 'core/authentication';
import { formatDuration, intervalToDuration } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { useMemo, useState } from 'react';
import { ChevronSmallDown } from '../Icons';
import CrossSmallIcon from '../Icons/CrossSmall';
import SearchIcon from '../Icons/Search';
import { ThesaurusItemCard } from './ThesaurusItemCard';
import { useThesaurusItems } from './useThesaurusItems';

type ThesaurusReasonAutocompleteProps = {
  value: ThesaurusItem | string | null | undefined;
  onChange: (value: ThesaurusItem | string | null) => void;
};

export const ThesaurusReasonAutocomplete = ({ value, onChange }: ThesaurusReasonAutocompleteProps) => {
  const { healthCenter, isLoading: isHealthCenterLoading } = useAuthentication();
  const { results, isLoading: isThesaurusLoading } = useThesaurusItems(healthCenter.id, !isHealthCenterLoading);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const isLoading = useMemo(
    () => isHealthCenterLoading || isThesaurusLoading,
    [isHealthCenterLoading, isThesaurusLoading]
  );
  const options = useMemo(() => {
    if (isLoading) {
      if (search !== value) {
        return [search];
      }
      return [];
    }

    if (search && search !== value && !results.find((result) => result.reason === search)) {
      return [search, ...results];
    }
    return results;
  }, [isLoading, search, value, results]);
  const thesaurusValue = useMemo(() => getOptionValue(value, results), [value, results]);

  const handleReset = () => {
    setSearch('');
    onChange(null);
  };

  if (!healthCenter.thesaurusReasonEnabled) {
    return (
      <TextField
        fullWidth
        multiline
        value={typeof value === 'string' ? value : value?.reason}
        onChange={(event) => onChange(event.target.value)}
        variant="filled"
        size="small"
      />
    );
  }

  return (
    <Stack spacing={2} width="100%">
      <Autocomplete
        size="small"
        fullWidth
        options={options}
        groupBy={(option) => typeof option}
        renderGroup={renderGroup}
        open={open}
        value={typeof value === 'string' ? value : undefined}
        inputValue={search}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        onInputChange={(_, search, reason) => {
          if (reason === 'input') {
            setSearch(search);
            setOpen(true);
          }
        }}
        onChange={(_, value) => {
          if (value) {
            onChange(getOptionValue(value, results));
            setSearch('');
            setOpen(false);
          }
        }}
        onBlur={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            value={search}
            onClick={() => {
              setOpen(!open);
            }}
            placeholder={i18n.thesaurusReasonPlaceholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon sx={{ marginRight: 2 }} />,
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {isLoading && <CircularProgress color="inherit" size={16} sx={{ marginRight: 3 }} />}
                    {search.length > 0 ? (
                      <IconButton onClick={handleReset} edge="end">
                        <CrossSmallIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => setOpen(!open)}
                        edge="end"
                        sx={{ rotate: open ? '180deg' : undefined }}
                      >
                        <ChevronSmallDown />
                      </IconButton>
                    )}
                  </>
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) =>
          typeof option !== 'string' ? (
            <Box
              {...props}
              component="li"
              key={option.id}
              style={{ padding: 12 }}
              sx={{
                ':hover': {
                  backgroundColor: palette.secondary.light,
                },
              }}
            >
              <Stack spacing={1} paddingX={2}>
                <Typography variant="h6">{option.reason}</Typography>
                {option.expectedDurationInMinutes && (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="overline">DMS</Typography>
                    <Typography variant="body2">
                      {formatDuration(
                        intervalToDuration({ start: 0, end: option.expectedDurationInMinutes * 60 * 1000 }),
                        { locale: fr }
                      )}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
          ) : (
            <li {...props} key={option} style={{ paddingTop: 8, paddingBottom: 8 }}>
              {option}
            </li>
          )
        }
        ListboxComponent={(props) => (
          <Stack {...props} spacing={2} divider={<Divider flexItem sx={{ paddingX: 2 }} />}>
            {props.children}
          </Stack>
        )}
        sx={{ '& .MuiInputBase-sizeSmall': { paddingRight: '8px !important' } }}
      />

      {thesaurusValue && <ThesaurusItemCard thesaurus={thesaurusValue} onDelete={handleReset} />}
    </Stack>
  );
};

const getOptionLabel = (option: ThesaurusItem | string | null) => {
  if (typeof option === 'string') {
    return option;
  }

  return option?.reason || '';
};

const getOptionValue = (
  value: ThesaurusItem | string | null | undefined,
  options: ThesaurusItem[]
): ThesaurusItem | string | null => {
  if (!value) {
    return null;
  }
  if (typeof value === 'string') {
    return options.find((option) => option.reason === value) || value;
  }
  return value;
};

const isOptionEqualToValue = (option: ThesaurusItem | string, value: ThesaurusItem | string | undefined) => {
  const optionReason = typeof option === 'string' ? option : option.reason;
  const valueReason = typeof value === 'string' ? value : value?.reason;

  return optionReason === valueReason;
};

const renderGroup = (params: AutocompleteRenderGroupParams) => {
  if (params.group === 'string') {
    return (
      <Stack {...params}>
        <Typography variant="subtitle2" lineHeight="12px" paddingX={4} paddingY={2}>
          {i18n.personalizedReason}
        </Typography>
        <Stack>{params.children}</Stack>
      </Stack>
    );
  }

  return (
    <Stack {...params}>
      <Stack direction="row" spacing="6px" paddingX={4} paddingY={2} alignItems="center">
        <Typography variant="subtitle2" lineHeight="12px">
          {i18n.thesaurusReason}
        </Typography>
        <Chip label={i18n.thesaurus.toUpperCase()} variant="outlined" size="small" color="info" />
      </Stack>
      <Stack>{params.children}</Stack>
    </Stack>
  );
};
