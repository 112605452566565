import { i18n } from 'common/locale';
import { PageHeader, PageSection } from 'core/layout';
import { DetailsDialog } from 'kurt/components';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccommodationResultList } from './Components/AccommodationResultList';
import { AccommodationSearchFilter, AccommodationSearchFilters } from './Components/AccommodationSearchFilters';
import { useOverviewResources } from './hooks/useOverviewResources';

export const Overview = () => {
  const [filters, setFilters] = useState<AccommodationSearchFilter>({
    date: new Date(),
    page: 0,
    rowsPerPage: 30,
  });

  const { resources, isLoading, count } = useOverviewResources(filters);

  return (
    <>
      <PageHeader title={i18n.bedsOverview.title} />
      <PageSection>
        <AccommodationSearchFilters filters={filters} onFiltersChange={setFilters} />
      </PageSection>
      <PageSection>
        <AccommodationResultList
          resources={resources}
          count={count}
          onChange={(page, rowsPerPage) => setFilters({ ...filters, page, rowsPerPage })}
          isLoading={isLoading}
        />
      </PageSection>

      <Routes>
        <Route path="accommodation/:id" element={<DetailsDialog />} />
        <Route path="mutation/:id" element={<DetailsDialog />} />
      </Routes>
    </>
  );
};
