import { AccommodationSpecificity, Cloud, Patient, ThesaurusItem } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  formControlLabelClasses,
} from '@mui/material';
import { DialogHeader } from 'common/components';
import CommentIcon from 'common/components/Icons/Comment';
import EditIcon from 'common/components/Icons/Edit';
import HeartBeatIcon from 'common/components/Icons/HeartBeat';
import PathIcon from 'common/components/Icons/Path';
import StandIcon from 'common/components/Icons/Stand';
import { PatientAutocomplete, PatientSearchResult } from 'common/components/PatientAutocomplete';
import { ThesaurusReasonAutocomplete } from 'common/components/ThesaurusReasonAutocomplete/ThesaurusReasonAutocomplete';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { formatName } from 'common/utils';
import { useLocations } from 'core/locations';
import { DialogFormRow } from 'kurt/components';
import SpecificityAutocomplete from 'kurt/components/SpecificityAutocomplete';
import UnitSelector from 'kurt/components/UnitAutocomplete';
import { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type RequestHospitalizationContentProps = {
  visit?: Patient;
  specificities?: AccommodationSpecificity[];
  comment?: string;
  unitId?: string;
  onClose: () => void;
  onSuccess: () => void;
  onBack?: () => void;
};

const initialErrors = {
  patient: false,
  unit: false,
};

export const RequestHospitalizationDialogContent = ({
  visit,
  onClose,
  onSuccess,
  onBack,
  unitId,
  specificities: initialSpecificities = [],
  comment: initialComment = '',
}: RequestHospitalizationContentProps) => {
  const { units } = useLocations();
  const unitOptions = units.filter((unit) => unit.id !== unitId).map((unit) => ({ value: unit.id, label: unit.name }));

  const [patient, setPatient] = useState<PatientSearchResult | null>(
    visit
      ? {
          birthdate: visit.birthdate,
          gender: visit.gender,
          id: visit.id,
          ipp: visit.ipp,
          name: formatName(visit.firstName, visit.lastName, visit.legalName, visit.legalFirstName),
          pan: visit.pan,
        }
      : null
  );

  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState<string>('');
  const [thesaurusItem, setThesaurusItem] = useState<ThesaurusItem | undefined>(undefined);
  const [comment, setComment] = useState<string>(initialComment);
  const [specificities, setSpecificities] = useState<AccommodationSpecificity[]>(initialSpecificities);
  const [isToAddress, setToAddress] = useState(false);
  const [unit, setUnit] = useState<{ value: string; label: string } | null>(null);

  const [errors, setErrors] = useState(initialErrors);
  const resetErrors = () => setErrors(initialErrors);
  useEffect(resetErrors, [patient, unit]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    resetErrors();

    if (!patient) {
      setErrors((errors) => ({ ...errors, patient: true }));
      return;
    }

    if (!unit && !isToAddress) {
      setErrors((errors) => ({ ...errors, unit: true }));
      return;
    }

    setLoading(true);

    try {
      await Cloud.createAccommodationRequest({
        requestType: 'INTERNAL_MUTATION',
        visitId: patient.id,
        comment,
        reason,
        thesaurusItemId: thesaurusItem?.id,
        specificities,
        performerUnitIds: isToAddress ? undefined : [unit!.value],
      });
    } catch (error) {
      toast.error(i18n.createHospitalizationRequestError);
    }

    setLoading(false);
    onSuccess();
  };

  const handleChangeText = (field: 'comment') => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const hasLineBreak = event.target.value.match(/$/gm);

    if (hasLineBreak && hasLineBreak.length > 3) {
      return;
    }

    if (field === 'comment') {
      setComment(event.target.value);
    }
  };

  const handleReasonChange = (value: ThesaurusItem | string | null) => {
    setThesaurusItem((typeof value !== 'string' && value) || undefined);
    setReason(typeof value === 'string' ? value : value?.reason || '');
  };

  const handleDestinationCheckboxChange = (event: React.SyntheticEvent, checked: boolean) => {
    setToAddress(checked);
    setUnit(null);
    setErrors((errors) => ({ ...errors, unit: false }));
  };

  return (
    <>
      <DialogHeader onClose={onClose} onBack={onBack} title={i18n.createHospitalizationRequest} />
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack divider={<Divider orientation="horizontal" />}>
            <DialogFormRow label={i18n.createHospitalizationRequestForm.patientStep.label} Icon={StandIcon}>
              <PatientAutocomplete
                value={patient}
                onChange={setPatient}
                unitId={unitId}
                error={errors.patient ? { message: i18n.addAccommodationModal.errors.patient } : undefined}
                variant="filled"
                size="small"
              />
            </DialogFormRow>
            <DialogFormRow label={i18n.createHospitalizationRequestForm.reasonStep.label} Icon={HeartBeatIcon}>
              <ThesaurusReasonAutocomplete value={thesaurusItem || reason} onChange={handleReasonChange} />
            </DialogFormRow>
            <DialogFormRow label={i18n.createHospitalizationRequestForm.specificitiesStep.label} Icon={EditIcon}>
              <SpecificityAutocomplete value={specificities} onChange={setSpecificities} />
            </DialogFormRow>
            <DialogFormRow label={i18n.createHospitalizationRequestForm.commentStep.label} Icon={CommentIcon}>
              <TextField
                multiline
                fullWidth
                disabled={loading}
                minRows={1}
                maxRows={3}
                variant="filled"
                size="small"
                placeholder={i18n.createHospitalizationRequestForm.commentStep.placeHolder}
                value={comment}
                onChange={handleChangeText('comment')}
              />
            </DialogFormRow>
            <DialogFormRow label={i18n.createHospitalizationRequestForm.destinationStep.label} Icon={PathIcon}>
              <Stack spacing={3}>
                <UnitSelector
                  options={unitOptions}
                  selected={unit}
                  onChange={(_, unit) => setUnit(unit)}
                  error={errors.unit ? { message: i18n.addAccommodationModal.errors.unit } : undefined}
                  variant="filled"
                  size="small"
                  disabled={isToAddress}
                />
                <FormControlLabel
                  control={<Checkbox checked={isToAddress} />}
                  label={i18n.createHospitalizationRequestForm.destinationStep.setToAddress}
                  onChange={handleDestinationCheckboxChange}
                  sx={{
                    gap: 1,
                    [`& .${formControlLabelClasses.label}`]: {
                      color: isToAddress ? 'primary.main' : 'text.primary',
                      fontWeight: isToAddress ? 600 : 400,
                    },
                  }}
                />
              </Stack>
            </DialogFormRow>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            {i18n.cancel}
          </Button>
          <LoadingButton type="submit" disabled={loading}>
            {i18n.createRequest}
          </LoadingButton>
        </DialogActions>
      </form>
    </>
  );
};

type RequestHospitalizationDialogProps = {
  visit?: Patient;
  specificities?: AccommodationSpecificity[];
  comment?: string;
  open: boolean;
  onClose: () => void;
};

export const CreateHospitalizationRequestDialog = ({
  open,
  onClose,
  visit,
  specificities,
  comment,
}: RequestHospitalizationDialogProps) => {
  const { unitId } = useParams<{ unitId: string }>();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <RequestHospitalizationDialogContent
        onClose={onClose}
        onSuccess={onClose}
        unitId={unitId}
        visit={visit}
        specificities={specificities}
        comment={comment}
      />
    </Dialog>
  );
};
