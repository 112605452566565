import { ThesaurusItem } from '@ambuliz/sabri-core';
import { Chip, IconButton, Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { formatDuration, intervalToDuration } from 'date-fns';
import fr from 'date-fns/locale/fr';
import CrossIcon from '../Icons/Cross';

type ThesaurusItemCardProps = {
  thesaurus: ThesaurusItem | string;
  variant?: 'outlined' | 'filled';
  onDelete?: () => void;
};

export const ThesaurusItemCard = ({ thesaurus, variant = 'outlined', onDelete }: ThesaurusItemCardProps) => {
  const thesaurusItem: ThesaurusItem | undefined = typeof thesaurus !== 'string' ? thesaurus : undefined;
  const reason = thesaurusItem?.reason || (typeof thesaurus === 'string' ? thesaurus : undefined);

  return (
    <Stack
      direction="row"
      spacing={2}
      padding={3}
      borderRadius={1}
      border="1px solid"
      borderColor={color.grey[10]}
      bgcolor={variant === 'filled' ? palette.background.default : undefined}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Stack spacing={1}>
        <Typography variant="body2" fontWeight={600}>
          {reason}
        </Typography>
        {thesaurusItem?.expectedDurationInMinutes && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="overline">DMS</Typography>
            <Typography variant="body2">
              {formatDuration(
                intervalToDuration({ start: 0, end: thesaurusItem.expectedDurationInMinutes * 60 * 1000 }),
                { locale: fr }
              )}
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack direction="row" gap={4} alignItems="center">
        {thesaurusItem && <Chip label={i18n.thesaurus.toUpperCase()} variant="outlined" size="small" color="info" />}

        {onDelete && (
          <IconButton onClick={onDelete} size="small">
            <CrossIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};
