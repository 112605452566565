import { AccommodationSpecificity, ThesaurusItem } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import { DateTimeInput, FilterSelect, ToggleGroup } from 'common/components';
import { Bed, Unit } from 'common/components/Icons';
import CalendarIcon from 'common/components/Icons/Calendar';
import CommentIcon from 'common/components/Icons/Comment';
import HeartBeatIcon from 'common/components/Icons/HeartBeat';
import ListIcon from 'common/components/Icons/List';
import PathIcon from 'common/components/Icons/Path';
import TicketIcon from 'common/components/Icons/Ticket';
import UsersOutlineIcon from 'common/components/Icons/UsersOutline';
import { PatientAutocomplete, PatientSearchResult } from 'common/components/PatientAutocomplete';
import { ThesaurusReasonAutocomplete } from 'common/components/ThesaurusReasonAutocomplete/ThesaurusReasonAutocomplete';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { useLocations } from 'core/locations';
import { useBedOptions } from 'core/locations/useBedOptions';
import { isValid } from 'date-fns';
import { AccommodationRequestToCreate } from 'kurt/hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import DialogFormRow from '../DialogFormRow';
import SpecificityAutocomplete from '../SpecificityAutocomplete/';
import UnitSelector from '../UnitAutocomplete';

type SendAccommodationRequestFormLightProps = {
  unitId: string;
  onSubmit: (accommodation: AccommodationRequestToCreate) => void;
  onCancel?: () => void;
  loading?: boolean;
  error?: ReactElement;
  initialPatient?: PatientSearchResult;
  comment?: string;
  specificities?: AccommodationSpecificity[];
};

const SendAccommodationRequestFormLight = ({
  unitId,
  onSubmit,
  onCancel,
  loading,
  error,
  initialPatient,
  comment,
  specificities,
}: SendAccommodationRequestFormLightProps) => {
  const [type, setType] = useState<AccommodationRequestType>('INTERNAL_MUTATION');
  const [patient, setPatient] = useState<PatientSearchResult | null>(initialPatient || null);
  const [errors, setErrors] = useState(initialErrors);
  const [accommodationRequest, setAccommodationRequest] = useState<Partial<AccommodationRequestForm>>({
    visitId: initialPatient?.id,
    comment,
    specificities,
  });
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState<null | { value: string; label: string }>(null);

  const { units } = useLocations();

  const { bedOptions, isLoading: areBedsLoading } = useBedOptions(unitId);
  const unitOptions = units.filter((unit) => unit.id !== unitId).map((unit) => ({ value: unit.id, label: unit.name }));

  const handleChange =
    <T extends keyof AccommodationRequestForm>(field: T) =>
    (value?: AccommodationRequestForm[T]) => {
      setAccommodationRequest({ ...accommodationRequest, [field]: value });
      setErrors({ ...errors, [field]: false });
    };

  const handleReasonChange = (value: ThesaurusItem | string | null) => {
    const newAccommodationRequest: Partial<AccommodationRequestForm> = {
      ...accommodationRequest,
      reason: typeof value === 'string' ? value : value?.reason || null,
      thesaurusItem: typeof value !== 'string' ? value : null,
    };

    setAccommodationRequest(newAccommodationRequest);
    setErrors({ ...errors, reason: false, thesaurusItem: false });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validation = validationByType[type];

    const validationErrors = validation(accommodationRequest);

    if (validationErrors && Object.values(validationErrors).includes(true)) {
      setErrors({ ...errors, ...validationErrors });
    } else {
      setHasBeenCalled(true);
      if (!hasBeenCalled) {
        const defaultAccommodationRequest: AccommodationRequestToCreate = {
          requestType: type,
          specificities: accommodationRequest.specificities || [],
          comment: accommodationRequest.comment || undefined,
          reason: accommodationRequest.reason || undefined,
          thesaurusItemId: accommodationRequest.thesaurusItem?.id || undefined,
          visitId: accommodationRequest.visitId!,
        };

        if (type === 'BED_MUTATION') {
          onSubmit({
            ...defaultAccommodationRequest,
            locationId: accommodationRequest.locationId!,
            expectedAt: accommodationRequest.expectedAt!,
          });
        } else {
          onSubmit({
            ...defaultAccommodationRequest,
            performerUnitIds: accommodationRequest.performerUnitIds!,
          });
        }
      }
    }
  };

  const handleChangeText =
    (field: 'reason' | 'comment') => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const hasLineBreak = event.target.value.match(/$/gm);

      if (hasLineBreak && hasLineBreak.length > 3) {
        return;
      }
      handleChange(field)(event.target.value);
    };

  useEffect(() => {
    if (!!error && !loading) {
      setHasBeenCalled(false);
    }
  }, [error, loading]);

  const handleChangeType = (type: AccommodationRequestType) => {
    setType(type);
    setErrors((prev) => ({ ...prev, expectedAt: false, locationId: false, performerUnitIds: false }));
  };

  return (
    <form onSubmit={handleSubmit} id="send-accommodation-request-form">
      <DialogContent>
        <Stack divider={<Divider orientation="horizontal" />}>
          <DialogFormRow Icon={TicketIcon} label={i18n.movementType}>
            <ToggleGroup
              onChange={(_, value) => {
                handleChangeType(value as AccommodationRequestType);
              }}
              value={type}
              variant="filled"
              size="small"
              fullWidth
            >
              <ToggleButton value="INTERNAL_MUTATION" sx={{ flex: 1 }}>
                <Stack direction="row" gap={2} alignItems="center">
                  <Unit sx={{ color: color.blue[30] }} />
                  {i18n.sendAccommodationRequestModal.selectToggle.internalHC.label}
                </Stack>
              </ToggleButton>
              <ToggleButton value="BED_MUTATION" sx={{ flex: 1 }}>
                <Stack direction="row" gap={2} alignItems="center">
                  <Bed sx={{ color: color.blue[30] }} />
                  {i18n.sendAccommodationRequestModal.selectToggle.internal.label}
                </Stack>
              </ToggleButton>
            </ToggleGroup>
          </DialogFormRow>
          <DialogFormRow Icon={UsersOutlineIcon} label="Patient">
            <PatientAutocomplete
              onChange={(patient) => {
                setPatient(patient);
                handleChange('visitId')(patient?.id);
              }}
              value={patient}
              size="small"
              variant="filled"
              unitId={unitId}
              error={errors.visitId ? { message: i18n.sendAccommodationRequestModal.errors.patient } : undefined}
            />
          </DialogFormRow>
          <DialogFormRow Icon={HeartBeatIcon} label={i18n.movementReason}>
            <ThesaurusReasonAutocomplete
              value={accommodationRequest.thesaurusItem || accommodationRequest.reason || null}
              onChange={handleReasonChange}
            />
          </DialogFormRow>
          <DialogFormRow Icon={ListIcon} label={i18n.specificities}>
            <SpecificityAutocomplete
              value={accommodationRequest.specificities || []}
              onChange={(value) => handleChange('specificities')(value)}
            />
          </DialogFormRow>
          <DialogFormRow Icon={CommentIcon} label={i18n.comment}>
            <TextField
              size="small"
              variant="filled"
              multiline
              minRows={1}
              maxRows={3}
              value={accommodationRequest.comment}
              placeholder={i18n.empty}
              onChange={(event) => handleChangeText('comment')(event)}
            />
          </DialogFormRow>
          {type === 'INTERNAL_MUTATION' && (
            <DialogFormRow Icon={PathIcon} label={i18n.sendAccommodationRequestSteps(type).guidance.label}>
              <UnitSelector
                options={unitOptions}
                selected={selectedUnits}
                onChange={(_, unit) => {
                  setSelectedUnits(unit);
                  handleChange('performerUnitIds')(unit?.value ? [unit.value] : undefined);
                }}
                error={
                  errors.performerUnitIds
                    ? { message: i18n.sendAccommodationRequestModal.errors.performerUnitIds }
                    : undefined
                }
                variant="filled"
                size="small"
              />
            </DialogFormRow>
          )}

          {type === 'BED_MUTATION' && (
            <DialogFormRow Icon={CalendarIcon} label={i18n.sendAccommodationRequestSteps(type).date.label}>
              <Stack spacing={1}>
                <Stack direction="row" justifyContent="space-between">
                  <DateTimeInput
                    value={accommodationRequest.expectedAt}
                    onChange={handleChange('expectedAt')}
                    error={errors.expectedAt}
                    variant="filled"
                    size="small"
                  />
                  <Button variant="text" onClick={() => handleChange('expectedAt')(new Date())}>
                    <Typography variant="link2" color={color.blue[60]}>
                      {i18n.dateNow}
                    </Typography>
                  </Button>
                </Stack>
                {errors.expectedAt && (
                  <Typography fontSize={12} color="error">
                    {i18n.sendAccommodationRequestModal.errors.expectedAt}
                  </Typography>
                )}
              </Stack>
            </DialogFormRow>
          )}

          {type === 'BED_MUTATION' && (
            <DialogFormRow Icon={Bed} label={i18n.sendAccommodationRequestSteps(type).location.label}>
              <Stack spacing={1}>
                <div style={{ alignSelf: 'flex-start' }}>
                  <FilterSelect
                    searchable
                    label={i18n.bedNumber}
                    value={accommodationRequest.locationId}
                    onChange={handleChange('locationId')}
                    error={errors.locationId}
                    variant="filled"
                    size="small"
                    loading={areBedsLoading}
                  >
                    {bedOptions.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </FilterSelect>
                </div>
                {errors.locationId && (
                  <Typography variant="body2" color="error">
                    {i18n.addAccommodationModal.errors.bed}
                  </Typography>
                )}
              </Stack>
            </DialogFormRow>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {i18n.cancel}
        </Button>
        <LoadingButton disabled={loading} loading={loading} type="submit" form="send-accommodation-request-form">
          {type === 'BED_MUTATION'
            ? i18n.sendAccommodationRequestModal.programmVisit
            : i18n.sendAccommodationRequestModal.programmDemand}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

type AccommodationRequestType = 'BED_MUTATION' | 'INTERNAL_MUTATION';

export type AccommodationRequestForm = {
  locationId: string;
  expectedAt: Date | null;
  visitId: string;
  specificities: AccommodationSpecificity[];
  performerUnitIds?: string[];
  reason?: string | null;
  thesaurusItem?: ThesaurusItem | null;
  comment?: string | null;
};

const initialErrors = {
  visitId: false,
  locationId: false,
  expectedAt: false,
  performerUnitIds: false,
  reason: false,
  thesaurusItem: false,
};
const validationByType: Record<
  AccommodationRequestType,
  (accommodation: Partial<AccommodationRequestForm>) => { [key in keyof AccommodationRequestForm]?: boolean }
> = {
  BED_MUTATION: (accommodationRequest) => ({
    visitId: !accommodationRequest.visitId,
    expectedAt: !accommodationRequest.expectedAt || !isValid(accommodationRequest.expectedAt),
    locationId: !accommodationRequest.locationId,
  }),

  INTERNAL_MUTATION: (accommodationRequest) => ({
    visitId: !accommodationRequest.visitId,
    performerUnitIds: !accommodationRequest.performerUnitIds || accommodationRequest.performerUnitIds.length === 0,
  }),
};

export default SendAccommodationRequestFormLight;
