import {
  Accommodation,
  AccommodationSpecificity,
  Area,
  ExternalPatientTransport,
  Patient,
  PatientOutcome,
  Practitioner,
  ThesaurusItem,
  Unit,
} from '@ambuliz/sabri-core';
import { Card, Stack, TextField, Typography } from '@mui/material';
import { DateTimeInput } from 'common/components';
import ClockIcon from 'common/components/Icons/Clock';
import CommentIcon from 'common/components/Icons/Comment';
import DoctorIcon from 'common/components/Icons/DoctorIcon';
import HeartBeatIcon from 'common/components/Icons/HeartBeat';
import ListIcon from 'common/components/Icons/List';
import UnitIcon from 'common/components/Icons/Unit';
import { ThesaurusItemCard } from 'common/components/ThesaurusReasonAutocomplete/ThesaurusItemCard';
import { ThesaurusReasonAutocomplete } from 'common/components/ThesaurusReasonAutocomplete/ThesaurusReasonAutocomplete';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { useAuthentication, UserHealthCenter } from 'core/authentication';
import { format, formatDistanceStrict, formatDistanceToNow, isPast } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { EditablePatientOutcomeRow, PatientOutcomeRow } from 'kurt/components/PatientOutcome';
import { UpcomingPatientTransportRow } from 'kurt/components/PatientTransport/UpcomingPatientTransportRow';
import { PractitionersAutocomplete } from 'kurt/components/Practitioners';
import SpecificityAutocomplete from 'kurt/components/SpecificityAutocomplete';
import { SpecificityChip } from 'kurt/components/SpecifityChip';
import AdmissionStatusChip from 'kurt/components/StepStatus/AdmissionStatusChip';
import DischargeStatusChip, { DischargeStatus } from 'kurt/components/StepStatus/DischargeStatusChip';
import DischargeStatusSelect from 'kurt/components/StepStatus/DischargeStatusSelect';
import DetailsRow from '../../DetailsRow';
import HorizontalDivider from '../../HorizontalDivider';
import { AccommodationStep } from '../../context/Step';

type AccommodationDetailsContentProps = {
  step: AccommodationStep;
  upcomingPatientTransport?: ExternalPatientTransport;
  onUpcomingPatientTransportClick: (id: string) => void;
  onOrderExternalTransportClick: (patient?: Patient) => void;
  editable?: boolean;
  onChange?: (accommodation: Accommodation) => void;
};

const AccommodationDetailsContent = ({
  step,
  editable,
  upcomingPatientTransport,
  onUpcomingPatientTransportClick,
  onOrderExternalTransportClick,
  onChange,
}: AccommodationDetailsContentProps) => {
  const handleSpecificitiesChange = (specificities: AccommodationSpecificity[]) => {
    const clone = cloneAccommodation(step.step);

    clone.specificities = specificities;
    onChange?.(clone);
  };

  const handleReasonChange = (value: ThesaurusItem | string | null) => {
    const clone = cloneAccommodation(step.step);

    clone.thesaurusItem = (typeof value !== 'string' && value) || undefined;
    clone.reason = typeof value === 'string' ? value : value?.reason;
    onChange?.(clone);
  };

  const handleCommentChange = (comment: string) => {
    const clone = cloneAccommodation(step.step);

    clone.comment = comment;
    onChange?.(clone);
  };

  const handleEstimatedEndChange = (isEstimatedEnd: boolean) => {
    const clone = cloneAccommodation(step.step);

    clone.isEstimatedEnd = isEstimatedEnd;
    onChange?.(clone);
  };

  const handleStartAtChange = (startAt: Date | null) => {
    if (!startAt) {
      return;
    }
    const clone = cloneAccommodation(step.step);

    clone.startAt = startAt;
    onChange?.(clone);
  };

  const handleEndAtChange = (endAt: Date | null) => {
    const clone = cloneAccommodation(step.step);

    clone.endAt = endAt || undefined;
    if (!endAt) {
      clone.isEstimatedEnd = true;
    }
    onChange?.(clone);
  };

  const handlePractitionersChange = (practitioners: (Practitioner | string)[]) => {
    const clone = cloneAccommodation(step.step);
    const newPractitioners: Practitioner[] = [];

    for (const practitioner of practitioners) {
      if (typeof practitioner === 'string') {
        newPractitioners.push({ name: practitioner });
      } else {
        newPractitioners.push(practitioner);
      }
    }
    clone.practitioners = newPractitioners;
    onChange?.(clone);
  };

  const handlePatientOutcomeChange = (patientOutcome: PatientOutcome) => {
    const clone = cloneAccommodation(step.step);

    if (clone.visit) {
      clone.visit.patientOutcome = patientOutcome;
      onChange?.(clone);
    }
  };

  const isAccommodationPast = isPast(step.step.startAt);
  const since = formatDistanceToNow(step.step.startAt, { locale: fr, includeSeconds: true });

  const duration = step.step.endAt ? formatDistanceStrict(step.step.endAt, step.step.startAt, { locale: fr }) : null;

  const startDateWarning = step.anomalies?.some(
    (anomaly) => anomaly.type === 'OUTDATED_START' && anomaly.status === 'OPENED'
  );

  const endDateWarning = step.anomalies?.some(
    (anomaly) =>
      ['OUTDATED_PREVISIONAL_END', 'OUTDATED_VALIDATED_END'].includes(anomaly.type) && anomaly.status === 'OPENED'
  );

  const handleDischargeStatusChange = (status: DischargeStatus) => {
    switch (status) {
      case 'ESTIMATED':
        handleEstimatedEndChange(true);
        break;
      case 'VALIDATED':
        handleEstimatedEndChange(false);
        break;
    }
  };

  const { healthCenter } = useAuthentication();
  const isUpcomingTransportVisible = healthCenter.patientTransportEnabled;

  return (
    <Card sx={{ borderTopLeftRadius: 0, marginTop: '-1px' }} elevation={1}>
      <Stack divider={<HorizontalDivider />}>
        <Stack direction="row" padding={4}>
          <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" flex={1}>
            <Stack direction="row" gap={2} flex={1}>
              {!!step.step.bed?.name && (
                <Typography fontWeight={600} padding="7px 8px">
                  {step.step.bed?.name}
                </Typography>
              )}
              <Typography fontWeight={600} padding="7px 8px">
                {step.step.unit?.name}
              </Typography>
            </Stack>
            <Stack direction="row" gap={4}>
              <Stack direction="row" gap={1} alignItems="center">
                <Stack direction="row" gap={2} alignItems="center">
                  <ClockIcon color="secondary" sx={{ fontSize: 12 }} />
                  <Typography variant="body2" fontWeight={600} color="secondary">
                    {i18n.duration.short.label}
                  </Typography>
                </Stack>
                <Typography color="secondary" variant="body2">
                  {duration || '-'}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <Typography variant="body2" fontWeight={600} color="secondary">
                  {isAccommodationPast ? i18n.since : i18n.in}
                </Typography>
                <Typography color="secondary" variant="body2">
                  {since}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" padding={4}>
          <Stack spacing={4} flex={1}>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="body2" color="secondary" paddingX={2} minWidth={53}>
                {i18n.detailsDialog.accommodations.content.start}
              </Typography>
              <Stack direction="row" gap={2} flex={1}>
                {editable ? (
                  <DateTimeInput
                    onChange={handleStartAtChange}
                    value={step.step.startAt}
                    maxDate={step.step.endAt}
                    error={step.step.endAt && step.step.startAt > step.step.endAt}
                    size="small"
                    variant="filled"
                    clearButton={false}
                  />
                ) : (
                  <>
                    <Typography padding="7px 8px" color={startDateWarning ? palette.warning.main : undefined}>
                      {format(step.step.startAt, 'dd/MM/yyyy')}
                    </Typography>
                    <Typography padding="7px 8px" color={startDateWarning ? palette.warning.main : undefined}>
                      {format(step.step.startAt, 'HH : mm')}
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack padding="6px 8px">
                {step.step.status === 'COMPLETED' || step.step.status === 'IN_PROGRESS' ? (
                  <AdmissionStatusChip status="ADMITTED" />
                ) : !!step.step.bed ? (
                  <AdmissionStatusChip status="ASSIGNED" />
                ) : !step.step.bed ? (
                  <AdmissionStatusChip status="TO_ASSIGN" />
                ) : null}
              </Stack>
            </Stack>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="body2" color="secondary" paddingX={2} minWidth={53}>
                {i18n.detailsDialog.accommodations.content.end}
              </Typography>
              <Stack direction="row" gap={2} flex={1}>
                {editable ? (
                  <DateTimeInput
                    onChange={handleEndAtChange}
                    value={step.step.endAt}
                    minDate={step.step.startAt}
                    error={step.step.endAt && step.step.startAt > step.step.endAt}
                    size="small"
                    variant="filled"
                  />
                ) : step.step.endAt ? (
                  <>
                    <Typography padding="7px 8px" color={endDateWarning ? palette.warning.main : undefined}>
                      {format(step.step.endAt, 'dd/MM/yyyy')}
                    </Typography>
                    <Typography padding="7px 8px" color={endDateWarning ? palette.warning.main : undefined}>
                      {format(step.step.endAt, 'HH : mm')}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography padding="7px 8px" color="secondary.medium" minWidth={91}>
                      {i18n.toDefine}
                    </Typography>
                    <Typography padding="7px 8px" color="secondary.medium">
                      {i18n.detailsDialog.common.noEndTime}
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack padding="6px 8px">
                {editable ? (
                  <DischargeStatusSelect value={getDischargeStatus(step)} onChange={handleDischargeStatusChange} />
                ) : (
                  <DischargeStatusChip status={getDischargeStatus(step)} />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack padding={4}>
          <Stack spacing={2} paddingLeft={2}>
            <DetailsRow
              Icon={HeartBeatIcon}
              label={i18n.detailsDialog.common.hospitalizationReason}
              value={
                <EditableReason
                  healthcenter={healthCenter}
                  step={step}
                  editable={editable}
                  onChange={handleReasonChange}
                />
              }
            />
            <DetailsRow
              Icon={DoctorIcon}
              label={i18n.detailsDialog.common.practitioners}
              value={
                editable ? (
                  <PractitionersAutocomplete
                    value={step.step.practitioners || []}
                    onChange={handlePractitionersChange}
                  />
                ) : (
                  step.step.practitioners?.length && (
                    <Stack spacing={2} padding="8px">
                      {step.step.practitioners?.map((practitioner) => (
                        <Typography variant="body2">{practitioner.name}</Typography>
                      ))}
                    </Stack>
                  )
                )
              }
            />
            <DetailsRow
              Icon={UnitIcon}
              label={i18n.detailsDialog.common.responsibleUnit}
              value={step.step.responsibleUnit?.name || step.step.unit.name}
            />
            <DetailsRow
              Icon={ListIcon}
              label={i18n.specificities}
              value={
                editable ? (
                  <SpecificityAutocomplete value={step.step.specificities || []} onChange={handleSpecificitiesChange} />
                ) : (
                  step.step.specificities?.length && (
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="center"
                      flexWrap="wrap"
                      alignSelf="center"
                      paddingLeft={2}
                    >
                      {step.step.specificities.map((specificity) => (
                        <SpecificityChip
                          key={specificity}
                          specificity={specificity}
                          sx={{ backgroundColor: palette.background.default }}
                        />
                      ))}
                    </Stack>
                  )
                )
              }
            />
            <DetailsRow
              Icon={CommentIcon}
              label={i18n.comment}
              value={
                editable ? (
                  <TextField
                    fullWidth
                    multiline
                    value={step.step.comment}
                    onChange={(event) => handleCommentChange(event.target.value)}
                    variant="filled"
                    size="small"
                  />
                ) : (
                  step.step.comment && (
                    <Typography variant="body2" whiteSpace="pre-line" padding={2}>
                      {step.step.comment}
                    </Typography>
                  )
                )
              }
            />
          </Stack>
        </Stack>

        {isUpcomingTransportVisible && (
          <UpcomingPatientTransportRow
            upcomingPatientTransport={upcomingPatientTransport}
            onUpcomingPatientTransportClick={onUpcomingPatientTransportClick}
            onOrderExternalTransportClick={() => onOrderExternalTransportClick(step.step.visit)}
          />
        )}

        {editable ? (
          <EditablePatientOutcomeRow
            patientOutcome={step.step?.visit?.patientOutcome}
            handlePatientOutcomeChange={handlePatientOutcomeChange}
          />
        ) : (
          <PatientOutcomeRow patient={step.step?.visit} />
        )}
      </Stack>
    </Card>
  );
};

const EditableReason = ({
  healthcenter,
  step,
  editable,
  onChange,
}: {
  healthcenter: UserHealthCenter;
  step: AccommodationStep;
  editable?: boolean;
  onChange: (value: ThesaurusItem | string | null) => void;
}) => {
  if (editable) {
    return <ThesaurusReasonAutocomplete onChange={onChange} value={step.step.thesaurusItem || step.step.reason} />;
  }

  if (healthcenter?.thesaurusReasonEnabled && step.step.reason) {
    return <ThesaurusItemCard variant="filled" thesaurus={step.step.thesaurusItem || step.step.reason} />;
  }
  return (
    <Typography variant="body2" whiteSpace="pre-line" padding={2}>
      {step.step.reason}
    </Typography>
  );
};

export const cloneAccommodation = (accommodation: Accommodation) => {
  const clone = Accommodation.fromJSON({
    ...accommodation.toJSON(),
    className: 'Accommodation',
  }) as Accommodation;

  if (accommodation.visit) {
    clone.visit = Patient.fromJSON({
      ...accommodation.visit.toJSON(),
      className: 'Patient',
    }) as Patient;
  }
  if (accommodation.bed) {
    clone.bed = Area.fromJSON({
      ...accommodation.bed.toJSON(),
      className: 'Area',
    }) as Area;
  }
  if (accommodation.unit) {
    clone.unit = Unit.fromJSON({
      ...accommodation.unit.toJSON(),
      className: 'Unit',
    }) as Unit;
  }
  if (accommodation.responsibleUnit) {
    clone.responsibleUnit = Unit.fromJSON({
      ...accommodation.responsibleUnit.toJSON(),
      className: 'Unit',
    }) as Unit;
  }
  if (accommodation.thesaurusItem) {
    clone.thesaurusItem = ThesaurusItem.fromJSON({
      ...accommodation.thesaurusItem.toJSON(),
      className: 'ThesaurusItem',
    }) as ThesaurusItem;
  }
  return clone;
};

const getDischargeStatus = (step: AccommodationStep) => {
  if (step.step.status === 'COMPLETED') return 'COMPLETED';
  if (step.step.isEstimatedEnd) return 'ESTIMATED';
  return 'VALIDATED';
};

export default AccommodationDetailsContent;
